import { connect } from 'react-redux';

import { Details } from 'components';
import windmills from '__mocks__/windmills';

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  return {
    windmill: windmills.find(w => w.id.toString() === id.toString()),
  };
};

export default connect(mapStateToProps)(Details);
