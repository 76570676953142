export default [{
  id: 1,
  name: 'WINDERNERGYDYUDINA',
  power: '60 kW',
  productions: [{
    value: 40,
    maxValue: 100,
    title: 'Produced today',
    details: '65.2 kWH'
  }, {
    value: 80,
    maxValue: 100,
    title: 'Monthly production',
    details: '5,505.0 kWH'
  }, {
    value: 234,
    maxValue: 1000,
    title: 'Yearly production',
    details: '31,665 kWH'
  }, {
    value: 3400,
    maxValue: 10000,
    title: 'Avg. power today',
    details: '10.2 kW'
  }, {
    value: 3400,
    maxValue: 10000,
    title: 'Avg. power this month',
    details: '1.5 kW'
  }, {
    value: 3400,
    maxValue: 10000,
    title: 'Avg. wind today',
    details: '4.8 m/s'
  }, {
    value: 3400,
    maxValue: 10000,
    title: 'Avg. wind this month',
    details: '2.9 m/s'
  }]
}, {
  id: 2,
  name: 'ASLRKEGLEKFVDVKLWEFLK',
  power: '70 kW',
  productions: [{
    value: 40,
    maxValue: 100,
    title: 'Produced today',
    details: '65.2 kWH'
  }, {
    value: 80,
    maxValue: 100,
    title: 'Monthly production',
    details: '5,505.0 kWH'
  }, {
    value: 234,
    maxValue: 1000,
    title: 'Yearly production',
    details: '31,665 kWH'
  }, {
    value: 3400,
    maxValue: 10000,
    title: 'Avg. power today',
    details: '10.2 kW'
  }, {
    value: 3400,
    maxValue: 10000,
    title: 'Avg. power this month',
    details: '1.5 kW'
  }, {
    value: 3400,
    maxValue: 10000,
    title: 'Avg. wind this month',
    details: '2.9 m/s'
  }]
}, {
  id: 3,
  name: 'ERGERGLKSDVLSKWE',
  power: '90 kW',
  productions: [{
    value: 40,
    maxValue: 100,
    title: 'Produced today',
    details: '65.2 kWH'
  }, {
    value: 80,
    maxValue: 100,
    title: 'Monthly production',
    details: '5,505.0 kWH'
  }, {
    value: 234,
    maxValue: 1000,
    title: 'Yearly production',
    details: '31,665 kWH'
  }, {
    value: 3400,
    maxValue: 10000,
    title: 'Avg. power today',
    details: '10.2 kW'
  }, {
    value: 3400,
    maxValue: 10000,
    title: 'Avg. power this month',
    details: '1.5 kW'
  }, {
    value: 3400,
    maxValue: 10000,
    title: 'Avg. wind this month',
    details: '2.9 m/s'
  }]
}]
