import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Responsive, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import 'flag-icon-css/css/flag-icon.css';

import { ProductionDetails } from 'components/shared';
import Settings from './Settings';

const NORMAL_OPTIONS = ['A', 'M'];
const FULL_DETAILS_OPTIONS = ['Automatic', 'Manual'];

const RowWrapper = styled.div`
  display: flex;
  
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: ${props => props.paddingBottom}px;
`;

const RowContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProductionTextWrapper = styled(RowContentWrapper)`
  @media (max-width: 991px) {
    justify-content: flex-start;
  }
  @media (min-width: 992px) {
    justify-content: flex-end;
  }
`;

const ProductionMobileWrapper = styled.div`
  margin-top: 16px;
`;

const CardFooter = ({ name, power, temperature, signal, fullDetails, size, productions, children }) => {
  const themeContext = useContext(ThemeContext);

  const renderProductions = (marginLeft) => (
    <Grid style={{ marginLeft, width: '100%', marginTop: fullDetails ? 12 : -16 }}>
      <Grid.Column computer={fullDetails || size === 'large' ? 10 : 16} tablet={16}>
        <RowContentWrapper style={{ justifyContent: fullDetails ? 'space-between' : 'flex-start'}}>
          <ProductionDetails
            value={40}
            maxValue={100}
            title="Produced today"
            details="65.2 kWH"
            color={themeContext.primaryColor}
          />
          <div style={{ marginLeft: fullDetails ? 0 : 32 }}>
            <ProductionDetails
              value={80}
              maxValue={100}
              title="Monthly production"
              details="5,505.0 kWH"
              color={themeContext.primaryColor}
            />
          </div>
          {fullDetails && (
            [
              <ProductionDetails
                key="yearly"
                value={234}
                maxValue={1000}
                title="Yearly production"
                details="31,665 kWH"
                color={themeContext.primaryColor}
              />,
              <ProductionDetails
                key="total"
                value={3400}
                maxValue={10000}
                title="Total production"
                details="218,577 kWH"
                color={themeContext.primaryColor}
              />
              ]
          )}
        </RowContentWrapper>
      </Grid.Column>

      {fullDetails && (
        <Grid.Column computer={6} tablet={16}>
          <ProductionTextWrapper style={{ marginRight: 24 }}>
            <div>
              <ProductionDetails
                value={3400}
                maxValue={10000}
                title="Avg. power today"
                details="10.2 kW"
                withProgress={false}
              />
              <ProductionDetails
                value={3400}
                maxValue={10000}
                title="Avg. power this month"
                details="1.5 kW"
                withProgress={false}
              />
            </div>
            <div style={{ marginLeft: 32 }}>
              <ProductionDetails
                value={3400}
                maxValue={10000}
                title="Avg. wind today"
                details="4.8 m/s"
                withProgress={false}
              />
              <ProductionDetails
                value={3400}
                maxValue={10000}
                title="Avg. wind this month"
                details="2.9 kW"
                withProgress={false}
              />
            </div>
          </ProductionTextWrapper>
        </Grid.Column>
      )}
      <RowContentWrapper style={{ marginLeft }}>

      </RowContentWrapper>
    </Grid>
  );

  const renderProductionsMobile = () => {
    if (!fullDetails) {
      return (
        <RowContentWrapper style={{ marginTop: 16, paddingLeft: 8, paddingRight: 8, marginBottom: 8 }}>
          {productions.slice(0, 2).map((p, index) => (
            <div key={p.title} style={{ marginLeft: index === 0 ? 0 : 32 }}>
              <ProductionDetails
                value={p.value}
                maxValue={p.maxValue}
                title={p.title}
                details={p.details}
                withProgress
                color={themeContext.primaryColor}
              />
            </div>
          ))}
        </RowContentWrapper>
      )
    }
    return (
      <div>
        {productions.map(p => (
          <ProductionMobileWrapper key={p.title}>
            <ProductionDetails
              value={p.value}
              maxValue={p.maxValue}
              title={p.title}
              details={p.details}
              withProgress={false}
            />
          </ProductionMobileWrapper>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div class="mobile hidden">
        <RowWrapper>
          <RowContentWrapper>
            <Settings options={fullDetails ? FULL_DETAILS_OPTIONS : NORMAL_OPTIONS} />
          </RowContentWrapper>
          {!fullDetails && renderProductions(50)}
        </RowWrapper>
        {fullDetails && (
          [
            <RowContentWrapper key="productions" style={{ marginTop: 16, marginBottom: 16 }}>
              {renderProductions(0)}
            </RowContentWrapper>,
          ]
        )}
      </div>
      <Responsive {...Responsive.onlyMobile} paddingBottom={16}>
        <div>
          <RowWrapper style={{ justifyContent: 'flex-end', marginTop: 16 }}>
            <Settings options={NORMAL_OPTIONS} />
          </RowWrapper>
        </div>
        <div>
          {renderProductionsMobile()}
        </div>
      </Responsive>
      {children}
    </div>
  );
};

CardFooter.propTypes = {
  fullDetails: PropTypes.bool,
};

CardFooter.defaultProps = {
  fullDetails: false,
};

export default CardFooter;
