import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ChartComponent, { Chart } from 'react-chartjs-2';
import 'chartjs-plugin-streaming';
import { Dropdown } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LineControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const randomScalingFactor = () => {
  return Math.round(Math.random() * 80);
};

const onRefresh = (chart) => {
  chart.config.data.datasets.forEach((dataset) => {
    dataset.data.push({
      x: Date.now(),
      y: randomScalingFactor()
    });
  });
};

const config = (theme) => ({
  type: 'line',
  data: {
    datasets: [{
      backgroundColor: theme.primaryColor,
      borderColor: theme.primaryColor,
      fill: false,
      cubicInterpolationMode: 'monotone',
      data: [],
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointHoverBackgroundColor: theme.primaryColor,
      pointHoverBorderColor: '#fff',
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverBorderWidth: 2,
      pointHoverRadius: 4,
    }, {
      backgroundColor: theme.secondaryColor,
      borderColor: theme.secondaryColor,
      fill: false,
      cubicInterpolationMode: 'monotone',
      data: [],
      pointBackgroundColor: 'transparent',
      pointHoverBackgroundColor: theme.secondaryColor,
      pointHoverBorderColor: '#fff',
      pointBorderColor: 'transparent',
      pointRadius: 0,
      pointBorderWidth: 0,
      pointHoverBorderWidth: 2,
      pointHoverRadius: 4,
    }]
  },
  options: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    scales: {
      xAxes: [{
        type: 'realtime',
        realtime: {
          duration: 60000,
          refresh: 1000,
          delay: 2000,
          onRefresh: onRefresh
        },
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: theme.textMedium
        },
      }],
      yAxes: [{
        gridLines: {
          drawBorder: false,
          color: theme.shadowColor,
          zeroLineColor: theme.shadowColor,
        },
        ticks: {
          fontColor: theme.textMedium
        },
      }],
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      bodySpacing: 4,
      callbacks: {
        label: function(tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ';
          }
          label += `${tooltipItem.yLabel} ${tooltipItem.datasetIndex === 0 ? 'kW' : 'm/s'}`;
          return label;
        }
      }
      // multiKeyBackground: 'red',
      // callbacks: {
      //   title: function(TooltipItems, obj) {
      //     const kWItem = TooltipItems.find(t => t.datasetIndex === 0);
      //     const msItem = TooltipItems.find(t => t.datasetIndex === 1);
      //     console.log(TooltipItems);
      //     console.log(obj);
      //     kWItem.value = `${TooltipItems[0].value} kW`;
      //     msItem.value = `${TooltipItems[1].value} m / s`;
      //
      //   }
      // }
    },
    hover: {
      mode: 'index',
      intersect: false
    }
  }
});

Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
  draw: function(ease) {
    if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
      var activePoint = this.chart.tooltip._active[0],
        ctx = this.chart.ctx,
        x = activePoint.tooltipPosition().x,
        topY = this.chart.scales['y-axis-0'].top,
        bottomY = this.chart.scales['y-axis-0'].bottom;

      // draw line on hover
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'rgb(206,206,206)';
      ctx.stroke();
      ctx.restore();
    }
    Chart.controllers.line.prototype.draw.call(this, ease);
  }
});

const LINE_ITEMS = ['Temperature', 'Power', 'Frequency'];

const LiveDataGraph = ({ height = 70 }) => {
  const themeContext = useContext(ThemeContext);

  const [firstLineItem, setFirstLineItem] = useState(LINE_ITEMS[0]);
  const [secondLineItem, setSecondLineItem] = useState(LINE_ITEMS[1]);

  const onLeftItemChange = (ev, { text }) => {
    setFirstLineItem(text);
  };

  const onRightItemChange = (ev, { text }) => {
    setSecondLineItem(text);
  };

  return (
    <ChartWrapper>
      <ChartComponent
        data={config(themeContext).data}
        options={config(themeContext).options}
        height={height}
        type="LineWithLine"
      />
      <LineControlsWrapper>
        <Dropdown text={firstLineItem} style={{ color: themeContext.textDark}}>
          <Dropdown.Menu>
            {LINE_ITEMS.map(i => (
              <Dropdown.Item key={i} text={i} onClick={onLeftItemChange}/>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown text={secondLineItem} style={{ color: themeContext.textDark}}>
          <Dropdown.Menu>
            {LINE_ITEMS.map(i => (
              <Dropdown.Item key={`right:${i}`} text={i} onClick={onRightItemChange} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </LineControlsWrapper>
    </ChartWrapper>
  );
};

LiveDataGraph.propTypes = {};

LiveDataGraph.defaultProps = {};

export default LiveDataGraph;
