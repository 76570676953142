import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import 'flag-icon-css/css/flag-icon.css';
import 'responsive-semantic-ui.css';
import media from 'styled-media-query';

import { SignalBar, Text } from 'components/shared';
import Temperature from './Temperature';
import RPM from './RPM';

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
`;

const RowContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled(props => <Text {...props} />)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  color: ${props => props.theme.textDark};
  font-weight: 900;
  text-overflow: ellipsis;
  
  ${media.lessThan('medium')`
    max-width: ${window.screen.availWidth * 0.4}px;
  `}
`;

const Power = styled(props => <Text {...props} />)`
  color: ${props => props.theme.textDark};
  font-weight: 100;
  margin-left: 8px;
`;

const CardHeader = ({ name, power, temperature, signal, isLarge, renderMenu, menuOnly, hideRPM }) => {
  const renderLeft = () => {
    if (menuOnly) {
      return <div />;
    }

    return (
      <RowContentWrapper>
        {/*<Flag />*/}
        <Name size={23}>{name}</Name>
        <Power size={isLarge ? 23 : 21}>{power}</Power>
      </RowContentWrapper>
    );
  };

  const renderRight = () => {
    if (menuOnly) {
      return <div />;
    }

    return (
      <RowContentWrapper>
        {isLarge && (
          <div style={{ display: 'flex', flexDirection: 'row' }} class="mobile hidden">
            {!hideRPM && <RPM value={27.5} />}
            <Temperature value={18.5} />
          </div>
        )}
        <SignalBar signal={3} />
      </RowContentWrapper>
    );
  };

  return (
    <Card.Header>
      <RowWrapper>
        {/*// TODO:*/}
        {/*// mobile only back button*/}
        {renderLeft()}
        <div class="mobile hidden">
          {renderMenu()}
        </div>
        {renderRight()}
      </RowWrapper>
      <div class="mobile only">
        {renderMenu()}
      </div>
    </Card.Header>
  );
};

CardHeader.propTypes = {
  renderMenu: PropTypes.func,
  menuOnly: PropTypes.bool,
  hideRPM: PropTypes.bool,
};

CardHeader.defaultProps = {
  renderMenu: () => null,
  menuOnly: false,
  hideRPM: false,
};

export default CardHeader;
