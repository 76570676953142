import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import CardHeader from './CardHeader';
import CardFooter from './CardFooter';
import CardContent from './CardContent';
import { Card, CardContent as CardContentContainer } from 'components/shared';

const CardWrapper = styled.div`
  width: 100%;
`;

const WindmillCard = ({ history, windmill, size, disabled, fullDetails }) => {
  const isLarge = size === 'large';

  const onClick = () => {
    history.push(`/${windmill.id}`)
  };

  return (
    <CardWrapper>
      <Card isLarge={isLarge} raised fluid onClick={disabled ? null : onClick}>
        <CardContentContainer>
          <CardHeader
            isLarge={isLarge}
            name={windmill.name}
            power={windmill.power}
            signal={windmill.signal}
            temperature={windmill.temperature}
            showMenu={fullDetails}
          />
          <CardContent showGraph={isLarge} />
          <CardFooter size={size} fullDetails={fullDetails} productions={windmill.productions} />
        </CardContentContainer>
      </Card>
    </CardWrapper>
  );
};

WindmillCard.propTypes = {
  size: PropTypes.oneOf(['normal', 'large']),
  disabled: PropTypes.bool,
  fullDetails: PropTypes.bool,
};

WindmillCard.defaultProps = {
  size: 'normal',
  disabled: false,
  fullDetails: false
};

export default withRouter(WindmillCard);
