import React from 'react';
import PropTypes from 'prop-types';

import { Input, Icon } from 'components/shared';

const InputField = ({ secureText, ...otherProps }) => {
  return (
    <Input icon iconPosition="left" {...otherProps} >
      <Icon name={secureText ? 'lock' : 'user circle'} />
      <input />
      {secureText && <Icon name="lock" />}
    </Input>
  );
};

InputField.propTypes = {
  secureText: PropTypes.bool,
};

InputField.defaultProps = {
  secureText: false,
};

export default InputField;
