import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { Button, Logo } from 'components/shared';

const Wrapper = styled.div`
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  position: relative;
`;

const styles = {
  button: {
    backgroundColor: 'transparent', boxShadow: '0 0 0 transparent', color: '#fff'
  }
};

const MobileHeader = ({ logout }) => {
  return (
    <Wrapper>
      <Button style={styles.button} icon onClick={() => {}}>
        <Icon name="align left" size="big" />
      </Button>
      <Logo size="mini" />
      <Button style={styles.button} icon onClick={logout}>
        <Icon name="user circle" size="big" />
      </Button>
    </Wrapper>
  );
};

MobileHeader.propTypes = {};

MobileHeader.defaultProps = {};

export default MobileHeader;
