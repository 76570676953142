import React from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { ContentContainer } from 'components/shared';
import WindmillCard from 'components/dashboard/WindmillCard';
import windmillsData from '__mocks__/windmills';

const WindmillsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  
  ${media.lessThan("large")`
    flex-direction: column;
  `}
  
`;

const WindmillWrapper = styled.div`
  display: flex;
  margin-left: ${props => props.marginLeft}px;
  margin-bottom: 30px;
  
  ${media.lessThan('large')`
    margin-left: 0;
  `}
`;

const Main = ({ data }) => {
  const renderWindmill = (windmill, size = 'normal') => (
    <WindmillCard windmill={windmill} size={size} />
  );

  const renderMainWindmill = () => (
    <Grid.Row columns={1}>
      <Grid.Column>
        {renderWindmill(data[0], 'large')}
      </Grid.Column>
    </Grid.Row>
  );

  const renderRestOfWindmills = () => {
    const windmills = [];

    for (let i = 1; i < data.length; i += 2) {
      windmills.push(
        <WindmillsWrapper key={`row: ${data[i].id}`}>
          <WindmillWrapper>
            {renderWindmill(data[i])}
          </WindmillWrapper>
          {data[i + 1] && (
            <WindmillWrapper marginLeft={30}>
              {renderWindmill(data[i + 1])}
            </WindmillWrapper>
          )}
        </WindmillsWrapper>
      )
    }

    return windmills;
  };

  return (
    <div style={{ width: '100%'}}>
      <ContentContainer>
        <div>
          {renderMainWindmill()}
          {renderRestOfWindmills()}
        </div>
      </ContentContainer>
    </div>
  )
};

const mapStateToProps = state => ({
  data: windmillsData,
  isLoading: false,
});

export default connect(mapStateToProps)(Main);
