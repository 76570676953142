import React from 'react';
import PropTypes from 'prop-types';

import LargeScreenHeader from './LargeScreenHeader';
import MobileHeader from './MobileHeader';

const AppHeader = ({ logout }) => {
  return (
    <div>
      <div class="mobile hidden">
        <LargeScreenHeader logout={logout} />
      </div>
      <div class="mobile only">
        <MobileHeader logout={logout} />
      </div>
    </div>
  );
};

AppHeader.propTypes = {
  logout: PropTypes.func,
};

AppHeader.defaultProps = {
  logout: () => {},
};

export default AppHeader;
