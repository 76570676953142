export const SET_THEME = 'SET_THEME';
export const THEME = 'THEME';

export const toggleTheme = () => (dispatch, getState) => {
  const currentTheme = getState().settings.theme;
  let newTheme = 'dark';
  if (currentTheme === 'dark') {
    newTheme = 'light';
  }

  localStorage.setItem(THEME, newTheme);
  dispatch(setTheme(newTheme));
};

export const setTheme = (theme) => ({
  type: SET_THEME,
  payload: { theme }
});
