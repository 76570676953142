import React from 'react';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledInput = styled(Input)`
  &[style] {
      border-radius: 10px;
  }
`;

const InputComponent = (props) => <StyledInput {...props} />;

export default InputComponent;
