import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { Icon, Button } from 'semantic-ui-react'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const styles = {
  button: {
    borderRadius: 20,
    height: 26,
    padding: '0 14px',
    boxShadow: '0 0 0 transparent',
    fontSize: 12,
  }
};

const Settings = ({ options }) => {
  const [selected, setSelected] = useState(options[0]);
  const themeContext = useContext(ThemeContext);

  return (
    <Wrapper>
      <Button.Group>
        {options.map(o => {
          const isSelected = selected.toLowerCase() === o.toLowerCase();

          return (
            <Button
              key={o}
              primary={isSelected}
              secondary={selected.toLowerCase() !== o.toLowerCase()}
              style={{
                ...styles.button,
                backgroundColor: isSelected ? themeContext.accentColor : themeContext.cardBackgroundColor,
                color: isSelected ? '#fff' : themeContext.textDark
              }}
              onClick={(e) => {
                e.stopPropagation();
                setSelected(o);
              }}
            >
              {isSelected ? o.toUpperCase() : o}
            </Button>
          )
        })}
      </Button.Group>
      <Icon name="setting" size="large" style={{ marginLeft: 8, color: themeContext.textDark }} onClick={(e) => {
        e.stopPropagation();
        alert('settings click')
      }} />
    </Wrapper>
  );
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;
