import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';

const TextComponent = styled.span`
  font-size: ${props => props.size}px;
  
  ${media.lessThan('medium')`
    font-size: ${props => props.size * 0.75}px;
  `}
  ${media.between('small', 'medium')`
    font-size: ${props => props.size * 0.9}px;
  `}
`;

const Text = ({ children, size, ...otherProps }) => (
  <TextComponent size={size} {...otherProps}>{children}</TextComponent>
);

Text.propTypes = {
  size: PropTypes.number,
};

Text.defaultProps = {
  size: 17,
};

export default Text;
