import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Container, Breadcrumb, Icon } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import media from 'styled-media-query';

const BreadcrumbWrapper = styled.div`
  margin-bottom: 24px;
`;

const StyledContainer = styled(Container)`
  padding: 20px 40px;
  
  ${media.lessThan('medium')`
    padding: 0;
  `}
`;

const ContentContainer = ({ children, withDashboardLink }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <StyledContainer fluid>
      <Link to="/">
        <BreadcrumbWrapper>
          <Breadcrumb size="medium">
            {withDashboardLink && (
              <Breadcrumb.Divider>
                <Icon name="left angle" size="large" style={{ color: themeContext.textDark, marginTop: -3 }}/>
              </Breadcrumb.Divider>
            )}
            <Breadcrumb.Section style={{ color: themeContext.textDark, fontWeight: '600' }} link>All Windmills</Breadcrumb.Section>
            {!withDashboardLink && (
              <Breadcrumb.Divider>
                <Icon name="down angle" size="large" color="gray" style={{ marginTop: -2 }} />
              </Breadcrumb.Divider>
            )}
          </Breadcrumb>
        </BreadcrumbWrapper>
      </Link>
      {children}
    </StyledContainer>
  );
};

ContentContainer.propTypes = {};

ContentContainer.defaultProps = {};

export default ContentContainer;
