import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import media from 'styled-media-query';
import { connect } from 'react-redux';

import Router from './routes/Router';
import theme from 'theme';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${media.greaterThan("large")`
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  `}
`;

const App = ({ themeName }) => (
  <Wrapper>
    <ThemeProvider theme={theme[themeName]}>
      <Router/>
    </ThemeProvider>
  </Wrapper>
);

const mapStateToProps = state => ({
  themeName: state.settings.theme,
});

export default connect(mapStateToProps)(App);
