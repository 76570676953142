import React from 'react';
import styled from 'styled-components';

import { ControlsMenu, ParametersTable } from 'components/shared';
import ControlButtons from './ControlButtons';
import {
  generateGridParams, generateRPMSParams, generateTemperatureParams,
  generateVibrationsParams, generateDigitalParams,
} from '__mocks__/params';

const Paragraph = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  width: 80%;
`;

const LiveControlsMenu = () => {
  const render2ColumnsTable = (key, params) => (
    <Paragraph key={key}>
      <ContentWrapper>
        <ParametersTable
          boldIndexes={[0]}
          params={params}
          cellStyle={{ padding: '12px 40px 12px 40px' }}
        />
      </ContentWrapper>
    </Paragraph>
  );

  const CONTROLS = [{
    label: 'CONTROLS',
    content: (
      <p>
        <ControlButtons />
      </p>
    )
  }, {
    label: 'WEATHER FORECAST',
    content: (
      <p>
        Weather forecast content
      </p>
    )
  }, {
    label: 'GRID',
    content: render2ColumnsTable('GRID', generateGridParams())
  }, {
    label: 'RPMS',
    content: render2ColumnsTable('RPMS', generateRPMSParams())
  }, {
    label: 'TEMPERATURE',
    content: render2ColumnsTable('TEMPERATURE', generateTemperatureParams())
  }, {
    label: 'VIBRATIONS',
    content: render2ColumnsTable('VIBRATIONS', generateVibrationsParams())
  }, {
    label: 'DIGITAL INPUTS',
    content: (
      <p>
        <ParametersTable
          key="input"
          boldIndexes={[4]}
          params={generateDigitalParams()}
          columnNames={['Name', 'Connector', 'Pin', 'Description', 'Value']}
          cellStyle={{ padding: '12px 40px 12px 40px' }}
        />
      </p>
    )
  }, {
    label: 'DIGITAL OUTPUTS',
    content: (
      <p>
        <ParametersTable
          key="output"
          boldIndexes={[4]}
          columnNames={['Name', 'Connector', 'Pin', 'Description', 'Value']}
          params={generateDigitalParams(false)}
          cellStyle={{ padding: '12px 40px 12px 40px' }}
        />
      </p>
    )
  }];

  return <ControlsMenu controls={CONTROLS} />;
};

LiveControlsMenu.propTypes = {};

LiveControlsMenu.defaultProps = {};

export default LiveControlsMenu;
