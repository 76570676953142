import React from 'react';
import styled from 'styled-components';

// import HelpAndFAQ from 'components/helpAndFAQ';
import { ThemeToggle } from 'components';

const Wrapper = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
`;

const Copyright = styled.span`
  color: ${props => props.theme.textDark};
`;

const AppFooter = () => {
  return (
    <Wrapper>
      <ThemeToggle />
      <Copyright>Copyright 2019</Copyright>
    </Wrapper>
  );
};

AppFooter.propTypes = {};

AppFooter.defaultProps = {};

export default AppFooter;
