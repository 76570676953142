import React from 'react';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledForm = styled(Form)`
`;

const FormComponent = (props) => <StyledForm {...props} />;

export default FormComponent;
