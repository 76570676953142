import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import Info from 'components/advanced/Info';
import ControlsMenu from './ControlsMenu';

const Wrapper = styled.div`
  padding: 100px 0;
  padding-bottom: 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 100px;
  
  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: center;
  `}
`;

const InfoItemWrapper = styled.div`
  ${media.lessThan('medium')`
    margin: 16px 0;
  `}
`;

const DATA = [{
  icon: 'lightning',
  title: 'Rated turbine power',
  subtitle: '60 kW',
},{
  icon: 'clock outline',
  title: '24h Connection availability',
  subtitle: '99.1%',
},{
  icon: 'calendar outline',
  title: 'Latest log',
  subtitle: '19 Jul 2019',
},{
  icon: 'calendar alternate outline',
  title: 'First log',
  subtitle: '29 Nov 2019',
}];

const Advanced = () => {
  const renderItems = () => (
    DATA.map(d => (
      <InfoItemWrapper key={d.title}>
        <Info icon={d.icon} title={d.title} subtitle={d.subtitle} />
      </InfoItemWrapper>
    ))
  );

  return (
    <Wrapper>
      <div class="mobile hidden">
        <InfoWrapper>
          {renderItems()}
        </InfoWrapper>
      </div>
      <div class="mobile only">
        <InfoWrapper>
          <div>
            {renderItems()}
          </div>
        </InfoWrapper>
      </div>
      <ControlsMenu />
    </Wrapper>
  )
};

export default Advanced;
