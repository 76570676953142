import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FiArrowRightCircle } from 'react-icons/fi';

import { Text } from 'components/shared';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TemperatureText = styled(props => <Text {...props} />)`
  color: ${props => props.theme.textDark};
  font-weight: 400;
  margin-left: 8px;
`;

const TemperatureDegrees = styled(TemperatureText)`
  color: ${props => props.theme.textMedium};
  margin-left: 4px;
  margin-right: 24px;
  font-weight: 300;
`;

const Temperature = ({ value }) => {
  return (
    <Wrapper>
      <FiArrowRightCircle color="gray" size="1.5em" />
      <TemperatureText size={23}>{value}</TemperatureText>
      <TemperatureDegrees size={21}>°C</TemperatureDegrees>
    </Wrapper>
  );
};

Temperature.propTypes = {};

Temperature.defaultProps = {};

export default Temperature;
