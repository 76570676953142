import React from 'react';

import { ControlButtons } from 'components/shared';

const SettingsControlButtons = () => {
  const onSetCurrentTime = () => {

  };

  const BUTTONS = [{
    name: 'Set current time',
    onClick: onSetCurrentTime,
  }];

  return <ControlButtons buttons={BUTTONS} />
};

SettingsControlButtons.propTypes = {};

SettingsControlButtons.defaultProps = {};

export default SettingsControlButtons;
