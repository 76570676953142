import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Dashboard, Licenses, NotFound } from 'components';
import Details from 'containers/details';
import PrivateRoute from 'routes/PrivateRoute';

const Router = (props) => (
  <Switch>
    <PrivateRoute exact path='/' component={Dashboard}/>
    <PrivateRoute exact path='/licenses' component={Licenses}/>
    <PrivateRoute exact path='/:id' component={Details}/>
    <Route exact path='/404' component={NotFound} />
    <Route render={() => <Redirect to="/"/>}/>
  </Switch>
);
export default Router;
