import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { GiBoatPropeller } from 'react-icons/gi';

import { Text } from 'components/shared';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Value = styled(props => <Text {...props} />)`
  color: ${props => props.theme.textDark};
  font-weight: 400;
  margin-left: 8px;
`;

const RPMText = styled(Value)`
  color: ${props => props.theme.textMedium};
  margin-left: 4px;
  margin-right: 24px;
  font-weight: 300;
`;

const RPM = ({ value }) => {
  return (
    <Wrapper>
      <GiBoatPropeller color="gray" size="1.5em" />
      <Value size={23}>{value}</Value>
      <RPMText size={21}>RPM</RPMText>
    </Wrapper>
  );
};

RPM.propTypes = {};

RPM.defaultProps = {};

export default RPM;
