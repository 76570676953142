import React from 'react';
import { Label } from 'semantic-ui-react';

const LabelComponent = ({ children, ...otherProps }) => (
  <Label {...otherProps}>
    {children}
  </Label>
);

export default LabelComponent;
