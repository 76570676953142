import React from 'react';
import { Card } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledCardContent = styled(Card.Content)`
  &&& {
      background-color: ${props => props.theme.cardBackgroundColor};
  }
`;

export default (props) => (
  <StyledCardContent {...props} />
);
