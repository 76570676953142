import React, { useContext, useState, useEffect } from 'react';
import { Menu } from 'components/shared';
import { ThemeContext } from "styled-components";

const BUTTONS = [{
  label: 'Hourly',
}, {
  label: 'Daily',
}, {
  label: 'Monthly',
}];

const PeriodButtons = ({ onButtonClick }) => {
  const themeContext = useContext(ThemeContext);
  const [activeItem, setActiveItem] = useState(BUTTONS[0].label);

  const onMenuItemClick = (item) => {
    setActiveItem(item);

    onButtonClick(item);
  };

  return (
    <Menu
      backgroundColor={themeContext.controlsBackgroundColor}
      links={BUTTONS}
      controlled
      activeLink={activeItem}
      onItemClick={onMenuItemClick}
      asAccordionMobile={false}
      withoutNavigation
    />
  )
};

PeriodButtons.propTypes = {};

PeriodButtons.defaultProps = {};

export default PeriodButtons;
