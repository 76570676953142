import React from 'react';

import AdvancedControlButtons from './AdvancedControlButtons';
import SettingsControlButtons from './SettingsControlButtons';
import { ControlsMenu, ParametersTable } from 'components/shared';
import { generateParams } from '__mocks__/params';

const ControlsMenuAdvanced = () => {
  const CONTROLS = [{
    label: 'ADVANCED CONTROLS',
    content: (
      <p>
        <AdvancedControlButtons />
      </p>
    )
  }, {
    label: 'PARAMETERS',
    content: (
      <p>
        <ParametersTable
          paginated
          editable
          numberOfItemsPerPage={7}
          params={generateParams(100)}
          columnNames={['Parameter number', 'Parameter name', 'Value', 'Unit']}
          cellStyle={{ padding: '4px 50px 4px 50px' }}
        />
      </p>
    )
  }, {
    label: 'SETTINGS',
    content: (
      <p>
        <SettingsControlButtons />
      </p>
    )
  }];

  return <ControlsMenu controls={CONTROLS} />;
};

ControlsMenuAdvanced.propTypes = {};

ControlsMenuAdvanced.defaultProps = {};

export default ControlsMenuAdvanced;
