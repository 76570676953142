import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import LiveDataGraph from 'components/live/LiveDataGraph';
import DataGraph from 'components/history/DataGraph';
import { Button, Icon } from 'components/shared';
import PeriodButtons from './PeriodButtons';

const Wrapper = styled.div`
`;

const GraphWrapper = styled.div`
  padding: 24px;
  padding-top: 50px;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.controlsBackgroundColor};
  margin-top: 16px;
  margin-left: -14px;
  margin-right: -14px;
  margin-bottom: -15px;
  border-radius: 8px;
  padding: 8px 24px;
`;

const DateButton = styled.button`
  border: 0;
  background-color: transparent;
  color: ${props => props.theme.textDark};
  
  &:hover {
    cursor: pointer
  }
`;

const DateButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowButton = styled.button`
  border: 0;
  outline: none;
  background-color: transparent;
  
  &:hover {
    cursor: pointer;
  }
`;

const TodayButton = styled(Button)`
  &&& {
    background-color: transparent;
    color: ${props => props.theme.textDark};
    border: 2px solid ${props => props.theme.accentColor};
    border-radius: 5px;
    box-shadow: 0;
  }
`;

const StyledIcon = styled(Icon)`
  &&& {
    color: ${props => props.color || props.theme.accentColor};
  }
`;

const History = () => {
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [date, setDate] = useState(new Date());
  const themeContext = useContext(ThemeContext);
  const [chartType, setChartType] = useState('Hourly');

  const handleChange = (d) => {
    setDate(d);
    toggleCalendar();
  };

  const toggleCalendar = (e) => {
    e && e.preventDefault();
    setDatePickerVisible(!datePickerVisible);
  };

  const onPrevious = () => {
    setDate(moment(date).add(-1, 'days').toDate());
  };

  const onNext = () => {
    setDate(moment(date).add(1, 'days').toDate());
  };

  const onToday = () => {
    setDate(new Date());
  };

  const onButtonClick = (item) => {
    setChartType(item);
  };

  const isBeforeToday = moment().isAfter(moment(date), 'day');

  return (
    <Wrapper>
      <GraphWrapper>
        { isBeforeToday ? <DataGraph key={`${date} ${chartType}`} chartType={chartType} /> : <LiveDataGraph />}
      </GraphWrapper>
      <DatePickerWrapper>
        <PeriodButtons onButtonClick={onButtonClick} />
        <DateButtonsWrapper>
          <ArrowButton onClick={onPrevious}>
            <StyledIcon name="chevron left" />
          </ArrowButton>
          <div>
            <DateButton
              onClick={toggleCalendar}>
              {moment(date).format('DD MMM YYYY')}
            </DateButton>
            {
              datePickerVisible && (
                <DatePicker
                  selected={date}
                  maxDate={new Date()}
                  onChange={handleChange}
                  withPortal
                  inline
                />
              )
            }
          </div>
          <ArrowButton onClick={isBeforeToday ? onNext : null}>
            <StyledIcon name="chevron right" color={isBeforeToday ? themeContext.accentColor : 'gray' } />
          </ArrowButton>
        </DateButtonsWrapper>
        <TodayButton onClick={onToday} secondary>
          Today
        </TodayButton>
      </DatePickerWrapper>
    </Wrapper>
  );
};

export default History;
