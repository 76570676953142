import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Menu, Logo, Icon } from 'components/shared';

const Wrapper = styled.div`
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  border: 1px solid transparent;
  border-bottom-color: ${props => props.theme.shadowColor};
  position: relative;
  background-color: ${props => props.theme.headerBackgroundColor};
`;

const SideWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
`;

const AppName = styled.span`
  font-size: 19px;
  font-weight: 600;
  color: ${props => props.themeName === 'light' ? props.theme.textDark : props.theme.accentColor};
`;

const AppDescription = styled.span`
  color: ${props => props.themeName === 'light' ? props.theme.textDark : props.theme.accentColor};
  margin-top: 2px;
  font-size: 13px;
`;

const LinksWrapper = styled.div`
  position:absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const UserName = styled.span`
  color: ${props => props.theme.textDark};
  font-size: 13px;
  font-weight: 700;
`;

const UserEmail = styled.span`
  font-size: 11px;
  color: ${props => props.theme.textMedium};
`;

const LINKS = [{
  label: 'DASHBOARD',
  to: '/'
}, {
  label: 'LICENSES',
  to: '/licenses'
}];

const LargeScreenHeader = ({ logout, themeName }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Wrapper>
      <SideWrapper>
        <Logo size="mini" />
        <ContentWrapper>
          <AppName themeName={themeName}>Orbital Italia</AppName>
          <AppDescription themeName={themeName}>SCADA Monitor for wind turbines</AppDescription>
        </ContentWrapper>
      </SideWrapper>
      <LinksWrapper>
        <Menu links={LINKS} defaultActiveItem={LINKS[0].label} backgroundColor={themeContext.headerBackgroundColor} />
      </LinksWrapper>
      <SideWrapper>
        <ContentWrapper style={{ alignItems: 'flex-end' }}>
          <UserName>Giuseppe Triolo</UserName>
          <UserEmail>giuseppe0076@gmail.com</UserEmail>
        </ContentWrapper>
        <Icon name="user circle" color="green" size="big" onClick={logout} />
      </SideWrapper>
    </Wrapper>
  );
};

LargeScreenHeader.propTypes = {
  logout: PropTypes.func,
};

LargeScreenHeader.defaultProps = {
  logout: () => {},
};

const mapStateToProps = state => ({
  theme: state.settings.theme,
});

export default connect(mapStateToProps)(LargeScreenHeader);
