import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import media from 'styled-media-query';

import { Auth, AppFooter } from 'components';
import AppHeader from 'containers/header';
import withIdCheck from 'hoc/withIdCheck';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 1px 1px 4px 2px rgba(0,0,0,0.1);
  
  background-color: ${props => props.theme.backgroundColor};
  
  ${media.lessThan('medium')`
    background-color: ${props => props.theme.primaryColor};
    width: 100%;
  `}
  
  ${media.between('medium', 'large')`
    height: 92%;
  `}

  ${media.greaterThan("huge")`
    height: 90%;
    width: 1400px;
  `}
  
  ${media.between("large", "huge")`
    width: ${window.screen.availWidth * 0.95}px;
  `}
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex: 1;
  min-height: ${(window.screen.availHeight - (window.outerHeight - window.innerHeight)) * 0.75}px;
`;

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route {...rest} render={(props) => (
    authenticated
      ? (
        <Wrapper>
          <AppHeader />
          <ComponentWrapper>
            <Component {...props} />
          </ComponentWrapper>
          <AppFooter />
        </Wrapper>
      )
      : <Auth {...props} />
  )} />
);

const mapStateToProps = state => ({
  authenticated: state.session.authenticated,
});

export default withIdCheck(connect(mapStateToProps)(PrivateRoute));
