import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Bar = styled.div`
  height: ${props => props.height};
  width: 4px;
  background-color: ${props => props.isOn ? props.theme.textDark : 'rgb(144,145,146)'};
  border-radius: 2px;
  margin-right: 2px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const SignalBar = ({ barCount = 4, height = 15, signal }) => {
  const bars = [];

  for (let i = 1; i <= barCount; i += 1) {
    bars.push(
      <Bar
        key={i}
        height={`${i * height / barCount - (i > 1 && i < barCount ? 1 : 0)}px`}
        isOn={signal >= i}
      />
    )
  }

  return (
    <Wrapper>
      {bars}
    </Wrapper>
  );
};

SignalBar.propTypes = {};

SignalBar.defaultProps = {};

export default SignalBar;
