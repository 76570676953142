const light = {
  primaryColor: '#26BC7E',
  secondaryColor: '#1150FE',
  accentColor: '#31AC9E',
  backgroundColor: '#fcfbfa',
  headerBackgroundColor: '#fff',
  shadowColor: '#e7e7e7',
  cardBackgroundColor: '#fff',
  controlsBackgroundColor: '#f7f7f7',

  // text
  textDark: '#44464B',
  textMedium: '#7B7E81',
  textMediumLight: '#B7B8BA',
  textLight: '#fff',
};

const dark = {
  primaryColor: '#26BC7E',
  secondaryColor: '#1150FE',
  accentColor: '#31AC9E',
  backgroundColor: '#454545',
  headerBackgroundColor: '#34363b',
  shadowColor: '#333333',
  cardBackgroundColor: '#505154',
  controlsBackgroundColor: '#4a4b4e',

  // text
  textDark: '#c8d4d3',
  textMedium: '#b4bfbe',
  textMediumLight: '#B7B8BA',
  textLight: '#fff',
};

export default {
  light,
  dark,
}
