import React from 'react';
import { Image } from 'semantic-ui-react'

import logoImg from 'images/logo.jpg';

const Logo = (props) => {
  return (
    <Image src={logoImg} wrapped {...props} />
  );
};

Logo.propTypes = {};

Logo.defaultProps = {};

export default Logo;
