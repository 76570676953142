import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Icon, Text } from 'components/shared';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DetailsWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
`;

const StyledIcon = styled(Icon)`
  &&& {
    color: ${props => props.theme.textDark};
  }
`;

const Title = styled(props => <Text {...props} />)`
  color: ${props => props.theme.textMedium};
  font-weight: 700;
`;

const Subtitle = styled(Title)`
  color: ${props => props.theme.textDark};
  margin-top: 2px;
`;

const Info = ({ icon, title, subtitle }) => {
  return (
    <Wrapper>
      <StyledIcon name={icon} size="big" />
      <DetailsWrapper>
        <Title size={11}>{title}</Title>
        <Subtitle size={17}>{subtitle}</Subtitle>
      </DetailsWrapper>
    </Wrapper>
  );
};

Info.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

Info.defaultProps = {};

export default Info;
