import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Menu, Icon } from 'semantic-ui-react';
import { ThemeContext } from 'styled-components';

import { Button } from 'components/shared';

const styles = {
  header: {
    padding: '8px 50px 8px 50px'
  },
  cell: {
    borderBottom: '2px solid black',
  }
};

const ParametersTable = ({
  params,
  paginated,
  columnNames,
  editable,
  numberOfItemsPerPage,
  cellStyle: cellStyleProp,
  boldIndexes,
}) => {
  const NUMBER_OF_ITEMS = numberOfItemsPerPage || params.length;
  const themeContext = useContext(ThemeContext);
  const [lastItemIndex, setLastItemIndex] = useState(NUMBER_OF_ITEMS - 1);
  const [data, setData] = useState([]);

  useEffect(() => {
    const sliceIndex = lastItemIndex === NUMBER_OF_ITEMS - 1 ? 0 : lastItemIndex - NUMBER_OF_ITEMS + 1;
    setData(params.slice(sliceIndex, lastItemIndex + 1));
  }, [lastItemIndex]);

  const cellStyle = {
    ...styles.cell,
    ...cellStyleProp,
    borderColor: themeContext.controlsBackgroundColor,
    color: themeContext.textDark
  };

  const headerStyle = {
    ...cellStyleProp,
    color: themeContext.textDark
  };

  const onPreviousPage = () => {
    if (lastItemIndex < NUMBER_OF_ITEMS) {
      return;
    }
    setLastItemIndex(lastItemIndex - NUMBER_OF_ITEMS);
  };

  const onNextPage = () => {
    if (lastItemIndex < params.length) {
      setLastItemIndex(lastItemIndex + NUMBER_OF_ITEMS);
    }
  };

  return (
    <Table basic='very'>
      <Table.Header>
        <Table.Row>
          {columnNames.map(c => (
            <Table.HeaderCell key={c} style={headerStyle}>{c}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <div style={{ marginTop: 8 }} />

      <Table.Body style={{ backgroundColor: themeContext.cardBackgroundColor }}>
        {data.map(p => (
          <Table.Row key={p.index}>
            {Object.keys(p).map((param, index) => {
              const fontStyle = boldIndexes.indexOf(index) > -1 ? { fontWeight: 'bold' } : {};
              const alignStyle = Object.keys(p).length === 2 && index === 1
                ? { textAlign: 'right' }
                : { };
              return (
                <Table.Cell key={param} style={{ ...cellStyle, ...alignStyle, ...fontStyle }}>{p[param]}</Table.Cell>
              )
            })}
            {editable && (
              <Table.Cell style={{ ...cellStyle, paddingRight: 0 }}>
                <Button icon secondary style={{ backgroundColor: 'transparent' }}>
                  <Icon name='pencil' />
                  <span style={{ marginLeft: 6 }}>Edit</span>
                </Button>
              </Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table.Body>

      {paginated && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='5'>
              <Menu floated='right' pagination style={{ backgroundColor: 'transparent', marginTop: 16, border: 0, borderShadow: 0, boxShadow: 0 }}>
                <Menu.Item as='a' icon onClick={onPreviousPage}>
                  <Icon name='chevron left' style={{ color: lastItemIndex <= NUMBER_OF_ITEMS ? 'gray' : themeContext.textDark }} />
                </Menu.Item>
                <Menu.Item as='a' icon onClick={onNextPage}>
                  <Icon name='chevron right' style={{ color: lastItemIndex > params.length ? 'gray' : themeContext.textDark }} />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}

    </Table>
  );
};

ParametersTable.propTypes = {
  params: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  paginated: PropTypes.bool,
  columnNames: PropTypes.arrayOf(PropTypes.string),
  editable: PropTypes.bool,
  numberOfItemsPerPage: PropTypes.number,
  cellStyle: PropTypes.shape({}),
  boldIndexes: PropTypes.arrayOf(PropTypes.number),
};

ParametersTable.defaultProps = {
  paginated: false,
  columnNames: [],
  editable: false,
  numberOfItemsPerPage: null,
  cellStyle: {},
  boldIndexes: [],
};

export default ParametersTable;
