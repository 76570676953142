import React from 'react';

import { ControlButtons } from 'components/shared';

const AdvancedControlButtons = () => {
  const onResetEventLogs = () => {

  };

  const onResetEnergyCounters = () => {

  };

  const onResetPeakValues = () => {

  };

  const onResetPowerCurve = () => {

  };

  const onRestartController = () => {

  };

  const onUpdateVibSensor = () => {

  };

  const onUpdateResetDebugData = () => {

  };

  const BUTTONS = [{
    name: 'Reset eventlogs',
    onClick: onResetEventLogs,
  }, {
    name: 'Reset energy counters',
    onClick: onResetEnergyCounters,
  }, {
    name: 'Reset peak values',
    onClick: onResetPeakValues,
  }, {
    name: 'Reset power curve',
    onClick: onResetPowerCurve,
  }, {
    name: 'Restart controller',
    onClick: onRestartController,
  }, {
    name: 'Update VibSensor',
    onClick: onUpdateVibSensor,
  }, {
    name: 'Reset debug data',
    onClick: onUpdateResetDebugData,
  }];

  return <ControlButtons buttons={BUTTONS} />
};

AdvancedControlButtons.propTypes = {};

AdvancedControlButtons.defaultProps = {};

export default AdvancedControlButtons;
