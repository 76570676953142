import React from 'react';

const Licenses = () => {
  return (
    <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <h1> This is the Licenses route</h1>
    </div>
  )
};

export default Licenses;
