import React from 'react';
import { Redirect } from 'react-router-dom';

const VALID_IDS = ['1', '2', '3'];

const withIdCheck = (WrappedComponent) => {
  return (props) => {
    const { computedMatch = {} } = props;

    if (computedMatch.params && computedMatch.params.id) {
      const isValidId = VALID_IDS.indexOf(computedMatch.params.id) > -1;

      if (!isValidId) {
        return <Redirect to="/404" />;
      }
    }

    return <WrappedComponent {...props} />;
  };
};

export default withIdCheck;
