import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Responsive } from "semantic-ui-react";
import { FaWind } from 'react-icons/fa';
import { GiElectric } from 'react-icons/gi';
import media from 'styled-media-query';

import { Progress } from 'components/shared';
import LiveDataGraph from 'components/live/LiveDataGraph';
import { shadeColor } from 'utils/colors';
import Temperature from 'components/dashboard/Temperature';
import RPM from 'components/dashboard/RPM';

const LiveDataContentWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent};
  align-items: center;
  flex: 1;
  
  ${media.lessThan('medium')`
    justify-content: space-between;
  `}
`;

const LiveDataWrapper = styled(LiveDataContentWrapper)`
  margin-bottom: 40px;
  margin-top: 32px;
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 50px;
    margin-top: 50px;
  }
`;

const WindSpeedWrapper = styled.div`
  margin-left: ${props => (props.marginLeft || 0)}px;
  
  ${media.lessThan('medium')`
    margin-left: 0;
  `}
`;

const CardContent = ({ showGraph, fullDetails }) => {
  const themeContext = useContext(ThemeContext);

  const renderGraph = (height) => (
    <LiveDataGraph height={height} />
  );

  return (
    <div>
      <LiveDataWrapper>
        <LiveDataContentWrapper justifyContent={showGraph ? 'flex-start' : 'space-between'}>
          <Progress
            type="dashboard"
            strokeColor={{
              '0%': shadeColor(themeContext.primaryColor, 10),
              '100%': themeContext.primaryColor,
            }}
            maxValue={25}
            value={20.4}
            strokeWidth={9}
            icon={<GiElectric color={themeContext.primaryColor} />}
            unit="kW"
          />

          <WindSpeedWrapper marginLeft={showGraph ? 100 : 0}>
            <Progress
              type="dashboard"
              strokeColor={{
                '0%': themeContext.secondaryColor,
                '100%': shadeColor(themeContext.secondaryColor, 90),
              }}
              maxValue={10}
              value={6.1}
              strokeWidth={9}
              icon={<FaWind color={themeContext.secondaryColor} />}
              unit="m/s"
            />
          </WindSpeedWrapper>

          {!showGraph && (
            <div>
              <Temperature value={18.5}/>
              <div style={{ marginTop: 16 }}>
                <RPM value={27.5} />
              </div>
            </div>
          )}
          {showGraph && (
            <div class="mobile only">
              <Temperature value={18.5}/>
              <div style={{ marginTop: 16 }}>
                <RPM value={27.5} />
              </div>
            </div>
          )}
        </LiveDataContentWrapper>
        {showGraph && (
          [<Responsive key="computer" {...Responsive.onlyComputer} style={{ flex: 3, marginLeft: 60 }}>
            {renderGraph()}
          </Responsive>,
            <Responsive key="tablet" {...Responsive.onlyTablet} style={{ flex: 3, marginLeft: 40 }}>
              {renderGraph()}
            </Responsive>]
        )}
      </LiveDataWrapper>
      {fullDetails && (
        <div style={{ marginTop: 50 }}>
          <Responsive key="mobile" {...Responsive.onlyMobile}>
            {renderGraph(120)}
          </Responsive>
        </div>
      )}
    </div>
  );
};

CardContent.propTypes = {};

CardContent.defaultProps = {};

export default CardContent;
