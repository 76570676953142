import React, { useContext, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import styled, { ThemeContext } from 'styled-components';
import times from 'lodash/times';
import moment from 'moment';

import RoundedBarsChart from './RoundedBarsChart';

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LineControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const generateChartData = (nb) => {
  const data = [];
  times(nb, () => data.push(Math.round(Math.random() * 80)));
  return data;
};

const getMonthDays = () => {
  const daysInMonth = [];

  const monthDate = moment().startOf('month');

  times(monthDate.daysInMonth(), function (n) {
    daysInMonth.push(monthDate.format('D'));
    monthDate.add(1, 'day');
  });

  return daysInMonth;
};

const CHART_LABELS = () => ({
  Monthly: {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    dataset1: generateChartData(12),
    dataset2: generateChartData(12),
  },
  Daily: {
    labels: getMonthDays(),
    dataset1: generateChartData(31),
    dataset2: generateChartData(31)
  },
  Hourly: {
    labels: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
    dataset1: generateChartData(24),
    dataset2: generateChartData(24)
  }
});

const config = (theme, chartType) => {
  const chartData = CHART_LABELS()[chartType];
  return {
    data: {
      labels: chartData.labels,
      datasets: [
        {
          backgroundColor: theme.accentColor,
          data: chartData.dataset1,
        },
        {
          backgroundColor: theme.secondaryColor,
          data: chartData.dataset2,
        }
      ],
    },
    options: {
      cornerRadius: 5,
      barWidth: chartType === 'Hourly' ? 14 : chartType === 'Monthly' ? 26 : 12,
      legend: {
        display: false
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem) {
            return tooltipItem.yLabel;
          }
        }
      },
      scales: {
        xAxes: [{
          startValue: 0,
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: theme.textMedium
          },
        }],
        yAxes: [{
          gridLines: {
            drawBorder: false,
            color: theme.controlsBackgroundColor,
            zeroLineColor: theme.controlsBackgroundColor,
          },
          ticks: {
            fontColor: theme.textMedium,
            beginAtZero: true,
          },
        }],
      },
    }
  }
};

const LINE_ITEMS = ['Temperature', 'Power', 'Frequency'];

const DataGraph = ({ height = 70, chartType }) => {
  const themeContext = useContext(ThemeContext);

  const [firstLineItem, setFirstLineItem] = useState(LINE_ITEMS[0]);
  const [secondLineItem, setSecondLineItem] = useState(LINE_ITEMS[1]);

  const onLeftItemChange = (ev, { text }) => {
    setFirstLineItem(text);
  };

  const onRightItemChange = (ev, { text }) => {
    setSecondLineItem(text);
  };

  return (
    <ChartWrapper>
      <RoundedBarsChart
        data={config(themeContext, chartType).data}
        options={config(themeContext, chartType).options}
        height={height}
      />
      <LineControlsWrapper>
        <Dropdown text={firstLineItem} style={{ color: themeContext.textDark }}>
          <Dropdown.Menu>
            {LINE_ITEMS.map(i => (
              <Dropdown.Item key={i} text={i} onClick={onLeftItemChange}/>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown text={secondLineItem} style={{ color: themeContext.textDark }}>
          <Dropdown.Menu>
            {LINE_ITEMS.map(i => (
              <Dropdown.Item key={`right:${i}`} text={i} onClick={onRightItemChange}/>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </LineControlsWrapper>
    </ChartWrapper>
  );
};

DataGraph.propTypes = {};

DataGraph.defaultProps = {};

export default DataGraph;
