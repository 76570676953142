import { createRequestTypes } from 'actions';
import { sessionService } from 'redux-react-session';

import * as sessionApi from 'api/sessionApi';
import { REMEMBER_LOGIN } from '../constants';

export const LOGIN = createRequestTypes('LOGIN');

// export const login = ({ username, password, rememberLogin = false }) => (dispatch) => {
//   dispatch({ type: LOGIN.REQUEST });
//   // do login
//   if (true) { // login success
//     dispatch({ type: LOGIN.SUCCESS });
//     localStorage.setItem(REMEMBER_LOGIN, JSON.stringify(rememberLogin));
//   } else {
//     dispatch({ type: LOGIN.FALSE });
//   }
//
// };

export const login = ({ username, password, rememberLogin = false }) => async (dispatch) => {
  const response = await sessionApi.login({ username });

  localStorage.setItem(REMEMBER_LOGIN, JSON.stringify(rememberLogin));
  const { token } = response;
  sessionService.saveSession({ token })
    .then(() => {
      sessionService.saveUser(response.data);
    }).catch(err => console.error(err));
};

export const logout = () => (dispatch) => {
  return sessionApi.logout().then(() => {
    sessionService.deleteSession();
    sessionService.deleteUser();
  }).catch(err => {
    throw (err);
  });
};
