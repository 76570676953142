import React from 'react';

import ControlButtons from 'components/shared/controls/ControlButtons';

const ControlButtonsLive = () => {
  const onStopClick = () => {

  };

  const onResetClick = () => {

  };

  const onMotorStartClick = () => {

  };

  const onMotorToggleClick = () => {

  };

  const onYawClockwise = () => {

  };

  const onYawCounterClockwise = () => {

  };

  const BUTTONS = [{
    name: 'Stop',
    onClick: onStopClick,
  }, {
    name: 'Reset',
    onClick: onResetClick,
  }, {
    name: 'Motor start',
    onClick: onMotorStartClick,
  }, {
    name: 'Motor toggle',
    onClick: onMotorToggleClick,
  }, {
    name: 'Yaw Clockwise',
    onClick: onYawClockwise,
  }, {
    name: 'Yaw Counter Clockwise',
    onClick: onYawCounterClockwise,
  }];

  return <ControlButtons buttons={BUTTONS} />;
};

ControlButtonsLive.propTypes = {};

ControlButtonsLive.defaultProps = {};

export default ControlButtonsLive;
