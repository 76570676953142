import React, { useState } from 'react';

import ControlButton from './ControlButton';

const ControlButtons = ({ buttons }) => {
  const [selectedButton, setSelectedButton] = useState(null);

  const onButtonClick = (b) => {
    let newButton = b;
    if (selectedButton && selectedButton.name === b.name) {
      newButton = null;
    }
    setSelectedButton(newButton);

    b.onClick();
  };

  return (
    buttons.map(b => (
      <ControlButton
        onClick={() => onButtonClick(b)}
        style={{ marginRight: 16 }}
        isHighlighted={selectedButton && selectedButton.name === b.name}
      >
        {b.name}
      </ControlButton>
    ))
  );
};

ControlButtons.propTypes = {};

ControlButtons.defaultProps = {};

export default ControlButtons;
