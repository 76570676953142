import React from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const StyledButton = styled(Button)`
  &&& {
      background-color: ${props => props.secondary ? '#fff' : props.theme.accentColor};
      color: ${props => !props.secondary ? props.theme.textLight : props.theme.accentColor};
      ${props => !props.secondary && 'box-shadow: 1px 1px 1px 1px #cfcfcf;'} 
      
      &&&:hover {
        background-color: ${props => !props.secondary ? props.theme.accentColor : '#fff'};
        ${props => !props.secondary && `box-shadow: 2px 2px 2px 1px #cfcfcf;`}
        color: ${props => !props.secondary ? props.theme.textLight : props.theme.accentColor};
      }
  }
`;

const ButtonComponent = ({ children, ...otherProps }) => (
  <StyledButton {...otherProps}>{children}</StyledButton>
);

ButtonComponent.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
};

ButtonComponent.defaultProps = {
  primary: true,
  secondary: false
};

export default ButtonComponent;
