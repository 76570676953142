import React, { useState } from 'react';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import {
  Logo, Label, Checkbox, Button,
  // Form, FormField
} from 'components/shared';
import InputField from './InputField';

// height: ${window.screen.availHeight - (window.outerHeight - window.innerHeight)}px;

const AuthWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100vh;
  padding: 60px 0;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
`;

const LogoInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormWrapper = styled.div`
  width: 80%;
  margin: 40px 0;
`;

const PersistAuthenticationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const styles = {
  infoLabel: {
    fontWeight: '500',
    backgroundColor: 'transparent',
    marginTop: -12
  },
  formElement: {
    marginBottom: 30,
  }
};

const LoginForm = ({ login }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberLogin, setRememberLogin] = useState(false);

  const onUsernameChange = (ev, { value }) => {
    setUsername(value);
  };

  const onPasswordChange = (ev, { value }) => {
    setPassword(value);
  };

  const onSubmit = () => {
    if (username === 'test' && password === 'test') {
      login({ username, password, rememberLogin });
    }
  };

  const onRememberLoginChange = (ev, { checked }) => {
    setRememberLogin(checked);
  };

  return (
    <AuthWrapper>
      <LogoInfoWrapper>
        <Logo size="tiny" />
        <Label size="huge" style={{ backgroundColor: '#fff', color: 'black', marginTop: 16 }}>Orbital Italia</Label>
        <Label size="medium" style={styles.infoLabel}>SCADA Monitor for wind turbines</Label>
      </LogoInfoWrapper>
      <FormWrapper onSubmit={onSubmit}>
        <Form style={{ display: 'flex', flexDirection: 'column' }}>
          <Form.Field>
            <InputField
              value={username}
              size="huge"
              style={styles.formElement}
              onChange={onUsernameChange}
            />
          </Form.Field>
          <Form.Field>
            <InputField
              value={password}
              type="password"
              size="huge"
              secureText
              style={styles.formElement}
              onChange={onPasswordChange}
            />
          </Form.Field>
          <PersistAuthenticationWrapper style={styles.formElement}>
            <Checkbox toggle checked={rememberLogin} onChange={onRememberLoginChange} />
            <Label size="medium" style={{...styles.infoLabel, marginTop: 0, marginLeft: 8 }}>
              Keep me signed in
            </Label>
          </PersistAuthenticationWrapper>
          <Button type="submit" size="huge" style={styles.formElement} onSubmit={onSubmit}>Log in</Button>
          <Button secondary style={styles.formElement}>Forgot password?</Button>
        </Form>
      </FormWrapper>
      <Label size="large" style={styles.infoLabel}>
        If you need any help, contact us at
        <Label as="a" size="large" style={{...styles.infoLabel, marginLeft: -7, color: 'green' }}>
          support@energytracker.eu
        </Label>
      </Label>
    </AuthWrapper>
  );
};

LoginForm.propTypes = {
  login: PropTypes.func,
};

LoginForm.defaultProps = {
  login: () => {},
};

export default LoginForm;
