import { SET_THEME, THEME } from 'actions/settings';

const INITIAL_STATE = {
  theme: localStorage.getItem(THEME) || 'dark'
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.payload.theme,
      };

    default: return state;
  }
}
