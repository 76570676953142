import { combineReducers } from 'redux';
import { sessionReducer } from 'redux-react-session';

import auth from './auth';
import settings from './settings';

export default combineReducers({
  auth,
  settings,
  session: sessionReducer,
});
