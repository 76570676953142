import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { Progress, Text } from 'components/shared';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled(props => <Text {...props} />)`
  font-weight: 500;
  color: ${props => props.theme.textDark};
`;

const Details = styled(Title)`
  font-weight: 700;
  margin-top: 2px;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const ProductionDetails = ({
  value,
  maxValue = 100,
  color = 'rgb(50,186,146)',
  title,
  details,
  withProgress = true,
}) => {
  if (withProgress) {
    return (
      <Wrapper>
        <Progress
          type="circle"
          strokeColor={color}
          strokeWidth={16}
          value={value}
          width={40}
          maxValue={maxValue}
          hideValueText
        />
        <DetailsWrapper>
          <Title size={12}>{title}</Title>
          <Details size={17}>{details}</Details>
        </DetailsWrapper>
      </Wrapper>
    )
  }
  return (
    <Wrapper style={{ justifyContent: 'space-between' }}>
      <Title size={12}>{title}</Title>
      <Details size={13} style={{ marginLeft: 16 }}>{details}</Details>
    </Wrapper>
  );
};

ProductionDetails.propTypes = {};

ProductionDetails.defaultProps = {};

export default ProductionDetails;
