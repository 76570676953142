import React, { useState, useEffect, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Menu, Accordion } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Icon } from 'components/shared';

const Wrapper = styled.div`
  display: flex;
`;

const StyledMenu = styled(Menu)`
  &&& {
    background-color: ${props => props.backgroundColor};
    border: 0;
    box-shadow: 0;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  &&& {
    color: ${props => props.color};
    font-weight: bold;
  }
`;

const Indicator = styled.div`
  background-color: ${props => props.theme.accentColor};
  height: 4px;
  border-radius: 2px;
  width: 100%;
`;

const NavigationLinks = ({
  asAccordionMobile = true, onItemClick, links, withoutNavigation, defaultActiveItem,
  controlled = false, activeLink, backgroundColor, disabledButtonIndexes,
}) => {
  const [activeItem, setActiveItem] = useState(defaultActiveItem);
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    if (controlled) {
      setActiveItem(activeLink);
    }
  }, [activeLink]);

  const handleItemClick = (e, item) => {
    onItemClick(item.name);
    if (!controlled) {
      setActiveItem(item.name);
    }
  };

  const handleMobileItemClick = (e, { index }) => {
    const item = links[index].label;
    onItemClick(item);
    if (!controlled) {
      setActiveItem(item);
    }
  };

  return (
    <Wrapper>
      <div class={asAccordionMobile && "mobile hidden"}>
        <StyledMenu compact secondary backgroundColor={backgroundColor}>
          {links.map((l, i) => {
            const isDisabled = disabledButtonIndexes.indexOf(i) > -1;
            const isActive = l.label === activeItem;
            const Item = (
              <div>
                <StyledMenuItem
                  style={{ backgroundColor, fontWeight: 'bold' }}
                  name={l.label}
                  active={isActive}
                  color={isDisabled ? themeContext.textMedium : isActive ? themeContext.textDark : themeContext.accentColor}
                  onClick={isDisabled ? undefined : handleItemClick}
                />
                {isActive && <Indicator />}
              </div>
            );

            return withoutNavigation
              ? Item
              : (
                <Link key={l.label} to={l.to}>
                  {Item}
                </Link>
              );
          })}
        </StyledMenu>
      </div>
      {asAccordionMobile && (
        <div class="mobile only" style={{ width: '100%'}}>
          <Accordion>
            {links.map((l, index) => (
              <div key={l.label}>
                <Accordion.Title
                  style={{ display: 'flex', justifyContent: 'space-between', color: 'rgb(77, 189, 179)' }}
                  active={activeItem === l.label}
                  index={index}
                  onClick={handleMobileItemClick}
                >
                  {l.label}
                  <Icon name='chevron right'/>
                </Accordion.Title>
                <Accordion.Content active={activeItem === l.label}>
                  {l.content}
                </Accordion.Content>
              </div>
            ))}
          </Accordion>
        </div>
      )}
    </Wrapper>
  );
};

NavigationLinks.propTypes = {
  withoutNavigation: PropTypes.bool,
  onItemClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  disabledButtonIndexes: PropTypes.arrayOf(PropTypes.number),
};

NavigationLinks.defaultProps = {
  withoutNavigation: false,
  onItemClick: () => {
  },
  backgroundColor: '#fff',
  disabledButtonIndexes: []
};

export default NavigationLinks;
