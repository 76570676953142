import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Button = styled.button`
  background-color: ${props => (props.isHighlighted ? props.theme.accentColor : props.theme.textDark )};
  color: #fff;
  padding: 16px;
  min-width: 150px;
  border-radius: 6px;
  text-align: left;
  outline: none;
  
  &:hover {
    cursor: pointer;
  }
`;

const ControlButton = ({ isHighlighted, children, ...otherProps }) => {
  const onClick = () => {

  };

  return (
    <Button isHighlighted={isHighlighted} onClick={onClick} {...otherProps}>
      {children}
    </Button>
  );
};

ControlButton.propTypes = {
  isHighlighted: PropTypes.bool,
};

ControlButton.defaultProps = {
  isHighlighted: false,
};

export default ControlButton;
