import React from 'react';
import { Card } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  &&& {
    background-color: ${props => props.theme.cardBackgroundColor};
    box-shadow: 1px 1px 4px 1px ${props => props.theme.shadowColor};
    
    &&&:hover {
      background-color: ${props => props.theme.cardBackgroundColor};
      box-shadow: 1px 1px 4px 2px ${props => props.theme.shadowColor};
    }
  }
`;

export default (props) => (
  <StyledCard {...props} />
);
