import React, { useContext, useState } from 'react';
// import { Card } from 'semantic-ui-react';

import CardHeader from 'components/dashboard/CardHeader';
import { Card, CardContent, ContentContainer, Menu } from 'components/shared';
import LiveContent from 'components/live';
import HistoryContent from 'components/history';
import AdvancedContent from 'components/advanced';
import styled, { ThemeContext } from "styled-components";


const MenuWrapper = styled.div`
  @media (max-width: 769px) {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }
  
  @media (min-width: 768px) {
    position: absolute;
    left: 50%;
    top: 40px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 200px;
    height: 50px;
    z-index: 1000;
  }
`;

const ITEMS = [{
  label: 'LIVE'
}, {
  label: 'HISTORY'
}, {
  label: 'ADVANCED'
}];

const Details = ({ windmill }) => {
  const [activeItem, setActiveItem] = useState(ITEMS[0].label);
  const themeContext = useContext(ThemeContext);

  const renderContent = () => {
    let Content = null;
    switch(activeItem) {
      case 'LIVE':
        Content = <LiveContent windmill={windmill} />;
        break;
      case 'HISTORY':
        Content = <HistoryContent windmill={windmill} />;
        break;
      case 'ADVANCED':
        Content = <AdvancedContent windmill={windmill} />;
        break;
      default: break;
    }
    return Content;
  };

  const onMenuItemClick = (item) => {
    setActiveItem(item);
  };

  const renderMenu = () => (
    <MenuWrapper>
      <Menu
        backgroundColor={themeContext.cardBackgroundColor}
        links={ITEMS}
        defaultActiveItem={ITEMS[0].label}
        onItemClick={onMenuItemClick}
        asAccordionMobile={false}
      />
    </MenuWrapper>
  );

  const Content = (
    <Card raised fluid>
      <CardContent>
        <CardHeader
          isLarge
          name={windmill.name}
          power={windmill.power}
          signal={windmill.signal}
          temperature={windmill.temperature}
          renderMenu={renderMenu}
          menuOnly={activeItem === 'HISTORY'}
          hideRPM={activeItem === 'ADVANCED'}
        />
        {renderContent()}
      </CardContent>
    </Card>
  );

  return (
    <div style={{ width: '100%' }}>
      <div class="mobile hidden">
        <ContentContainer withDashboardLink>
          {Content}
        </ContentContainer>
      </div>
      <div class="mobile only">
        {Content}
      </div>
    </div>
  );
};

export default Details;
