import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';

import Circle from './Circle';
import { Text } from 'components/shared';

const ContentWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  right: 50%;
  top: 40%;
  background-color: red;
`;

const Title = styled(props => <Text {...props} />)`
  margin-top: 18px;
  font-weight: bold;
  color: ${props => props.theme.textDark};
`;

const Subtitle = styled(props => <Text {...props} />)`
  font-weight: 300;
  color: ${props => props.theme.textMedium};
`;

const CircleWrapper = styled.div`
  position: relative;
  
  ${media.lessThan('medium')`
    width: ${props => props.width || 60}px;
    height: ${props => props.width || 60}px;
    font-size: ${props => ((props.width || 60) * 0.15 + 6)}px;
  `}

  ${media.between("medium", "large")`
    width: ${props => props.width || 100}px;
    height: ${props => props.width || 100}px;
    font-size: ${props => ((props.width || 40) * 0.15 + 6)}px;
  `}

  ${media.greaterThan("large")`
    width: ${props => props.width || 120}px;
    height: ${props => props.width || 120}px;
    font-size: ${props => ((props.width || 120) * 0.15 + 6)}px;
  `}
`;

const ProgressTypes = ['line', 'circle', 'dashboard'];
const ProgressStatuses = ['normal', 'exception', 'active', 'success'];

const Progress = ({
                    prefixCls,
                    width,
                    strokeWidth,
                    trailColor,
                    strokeLinecap,
                    gapPosition,
                    gapDegree,
                    type,
                    strokeColor,
                    value,
                    maxValue,
                    minValue,
                    icon,
                    unit,
                    hideValueText
                  }) => {

  const circleWidth = strokeWidth || 6;
  const gapPos = gapPosition || (type === 'dashboard' && 'bottom') || 'top';
  const gapDeg = gapDegree || (type === 'dashboard' ? 75 : undefined);

  const progress = value * 100 / maxValue;
  return (
    <CircleWrapper width={width}>
      <Circle
        percent={progress}
        strokeWidth={circleWidth}
        trailWidth={circleWidth}
        strokeColor={strokeColor}
        strokeLinecap={strokeLinecap}
        trailColor={trailColor}
        prefixCls={prefixCls}
        gapDegree={gapDeg}
        gapPosition={gapPos}
      />
      <ContentWrapper>
        {icon}
        {!hideValueText && <Title size={18}>{value}</Title>}
        <Subtitle size={16}>{unit}</Subtitle>
      </ContentWrapper>
    </CircleWrapper>
  );
};

Progress.propTypes = {
  status: PropTypes.oneOf(ProgressStatuses),
  type: PropTypes.oneOf(ProgressTypes),
  showInfo: PropTypes.bool,
  percent: PropTypes.number,
  width: PropTypes.number,
  strokeWidth: PropTypes.number,
  strokeLinecap: PropTypes.oneOf(['round', 'square']),
  strokeColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  trailColor: PropTypes.string,
  format: PropTypes.func,
  gapDegree: PropTypes.number,
  unit: PropTypes.string,
  hideValueText: PropTypes.bool,
};

Progress.defaultProps = {
  type: 'line',
  percent: 0,
  showInfo: true,
  trailColor: '#efefef',
  size: 'default',
  gapDegree: 0,
  strokeLinecap: 'round',
  hideValueText: false,
};

export default Progress;
