import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';

import LoginForm from 'containers/auth/LoginForm';

const FormColumn = styled(Grid.Column)`
  margin-right: -28px;
  @media (min-width: 768px) {
    max-width: 600px;
  }
`;

const EmptyColumn = styled(Grid.Column)`
  @media (min-width: 768px) {
    display: flex;
    flex: 1;
  }
`;

const Authentication = () => {
  return (
    <Grid style={{ flex: 1 }}>
      <FormColumn mobile={16} tablet={6} computer={6}>
        <LoginForm />
      </FormColumn>
      <EmptyColumn computer={10} tablet={10}>
        <div style={{ display: 'flex', position: 'relative', height: '100%', width: '100%' }} class="mobile hidden" />
      </EmptyColumn>
    </Grid>
  )
};

export default Authentication;
