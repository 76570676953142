const UNITS = ['Select', 'Seconds', 'm/s'];

export const generateParams = (nb = 100) => {
  const params = [];

  for (let i = 1; i <= nb; i += 1) {
    params.push({
      index: i,
      name: `Parameter ${i}`,
      value: Math.round(Math.random() * 100),
      unit: UNITS[Math.floor(Math.random() * UNITS.length)],
    })
  }

  return params;
};

const GRID_UNITS = ['A', 'V', 'Hz', '', 'kW'];

export const generateGridParams = (nb = 10) => {
  const params = [];

  for (let i = 1; i <= nb; i += 1) {
    let value = Math.round(Math.random() * 200);
    const unit = GRID_UNITS[Math.floor(Math.random() * GRID_UNITS.length)];
    value = unit.length ? `${value} ${unit}` : value;

    params.push({
      name: `Grid Parameter ${i}`,
      value,
    })
  }

  return params;
};

const RPMS_UNITS = ['A', 'V'];

export const generateRPMSParams = (nb = 4) => {
  const params = [];

  for (let i = 1; i <= nb; i += 1) {
    let value = Math.round(Math.random() * 200);
    const unit = RPMS_UNITS[Math.floor(Math.random() * RPMS_UNITS.length)];

    params.push({
      name: `RPMS Parameter ${i}`,
      value: `${value} ${unit}`,
    })
  }

  return params;
};

const TEMPERATURE_UNITS = ['°C', 'DM'];

export const generateTemperatureParams = (nb = 8) => {
  const params = [];

  for (let i = 1; i <= nb; i += 1) {
    let value = Math.round(Math.random() * 200);
    const unit = TEMPERATURE_UNITS[Math.floor(Math.random() * TEMPERATURE_UNITS.length)];
    value = unit === 'DM' ? unit : `${value} ${unit}`;

    params.push({
      name: `Temperature Parameter ${i}`,
      value,
    })
  }

  return params;
};

const VIBRATIONS_UNITS = ['m/s'];

export const generateVibrationsParams = (nb = 4) => {
  const params = [];

  for (let i = 1; i <= nb; i += 1) {
    let value = Math.round(Math.random() * 200);
    const unit = VIBRATIONS_UNITS[Math.floor(Math.random() * VIBRATIONS_UNITS.length)];
    value = `${value} ${unit}`;

    params.push({
      name: `Vibrations Parameter ${i}`,
      value,
    })
  }

  return params;
};

const DI_INPUTS = ['CW', 'Braked', 'OK', 'Pressure OK'];

export const generateDigitalParams = (input = true, nb = 10) => {
  const params = [];

  for (let i = 1; i <= nb; i += 1) {
    const value = DI_INPUTS[Math.floor(Math.random() * DI_INPUTS.length)];

    params.push({
      name: `${input ? 'DI' : 'DO'} ${i}`,
      connector: `X${Math.floor(Math.random() * 99)}`,
      pin: `${Math.floor(Math.random() * 99)}.${Math.floor(Math.random() * 99)}`,
      description: `Description ${i}`,
      value,
    })
  }

  return params;
};
