import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { sessionService } from 'redux-react-session';

import rootReducer from './reducers';
import { REMEMBER_LOGIN } from './constants';

const validateSession = (session) => {
  return JSON.parse(localStorage.getItem(REMEMBER_LOGIN));
};

export default function configureStore() {
  const store = createStore(
    rootReducer,
    applyMiddleware(thunk, logger)
  );

  const options = { redirectPath: '/', validateSession };
  sessionService.initSessionService(store, options);

  return store;
}
