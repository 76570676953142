import React from 'react';
import PropTypes from 'prop-types';

import CardFooter from '../dashboard/CardFooter';
import CardContent from '../dashboard/CardContent';
import ControlsMenu from './ControlsMenu';

const Live = ({ windmill }) => {
  return (
    <div>
      <CardContent showGraph fullDetails />
      <CardFooter size="large" fullDetails productions={windmill.productions}>
        <ControlsMenu />
      </CardFooter>
    </div>
  )
};

export default Live;
