import React from 'react';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';

// /* Input Focus */
// @inputBorderColor: transparent;
// @inputBorder: none;
// @inputBoxShadow: 1px 1px 3px #ccc;
// @inputFocusBoxShadow: 2px 2px 6px #ccc;

const StyledFormField = styled(Form.Field)`
  &[style] {
      box-shadow: 1px 1px 3px #ccc;
  }
`;

export default props => <StyledFormField {...props} />;
